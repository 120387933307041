/** Generated: Wednesday, 22nd of September 2021, 03:21:23 AM // Powered by AIOM+ (All In One Minify) created by FlipZoom Media Inc. - David Karich (flipzoom.de) **/


/* thai */

@font-face {
    font-family: 'Chakra Petch';
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/chakrapetch/v4/cIflMapbsEk7TDLdtEz1BwkebIl1U5_F7AY.woff2) format('woff2');
    unicode-range: U+0E01-0E5B, U+200C-200D, U+25CC;
}


/* vietnamese */

@font-face {
    font-family: 'Chakra Petch';
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/chakrapetch/v4/cIflMapbsEk7TDLdtEz1BwkebIl1SJ_F7AY.woff2) format('woff2');
    unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}


/* latin-ext */

@font-face {
    font-family: 'Chakra Petch';
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/chakrapetch/v4/cIflMapbsEk7TDLdtEz1BwkebIl1SZ_F7AY.woff2) format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}


/* latin */

@font-face {
    font-family: 'Chakra Petch';
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/chakrapetch/v4/cIflMapbsEk7TDLdtEz1BwkebIl1R5_F.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}


/* cyrillic-ext */

@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/roboto/v27/KFOmCnqEu92Fr1Mu72xKOzY.woff2) format('woff2');
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}


/* cyrillic */

@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/roboto/v27/KFOmCnqEu92Fr1Mu5mxKOzY.woff2) format('woff2');
    unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}


/* greek-ext */

@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/roboto/v27/KFOmCnqEu92Fr1Mu7mxKOzY.woff2) format('woff2');
    unicode-range: U+1F00-1FFF;
}


/* greek */

@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/roboto/v27/KFOmCnqEu92Fr1Mu4WxKOzY.woff2) format('woff2');
    unicode-range: U+0370-03FF;
}


/* vietnamese */

@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/roboto/v27/KFOmCnqEu92Fr1Mu7WxKOzY.woff2) format('woff2');
    unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}


/* latin-ext */

@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/roboto/v27/KFOmCnqEu92Fr1Mu7GxKOzY.woff2) format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}


/* latin */

@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/roboto/v27/KFOmCnqEu92Fr1Mu4mxK.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}


/* cyrillic-ext */

@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/roboto/v27/KFOlCnqEu92Fr1MmWUlfCRc4EsA.woff2) format('woff2');
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}


/* cyrillic */

@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/roboto/v27/KFOlCnqEu92Fr1MmWUlfABc4EsA.woff2) format('woff2');
    unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}


/* greek-ext */

@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/roboto/v27/KFOlCnqEu92Fr1MmWUlfCBc4EsA.woff2) format('woff2');
    unicode-range: U+1F00-1FFF;
}


/* greek */

@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/roboto/v27/KFOlCnqEu92Fr1MmWUlfBxc4EsA.woff2) format('woff2');
    unicode-range: U+0370-03FF;
}


/* vietnamese */

@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/roboto/v27/KFOlCnqEu92Fr1MmWUlfCxc4EsA.woff2) format('woff2');
    unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}


/* latin-ext */

@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/roboto/v27/KFOlCnqEu92Fr1MmWUlfChc4EsA.woff2) format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}


/* latin */

@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/roboto/v27/KFOlCnqEu92Fr1MmWUlfBBc4.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

.group:after {
    content: "";
    display: table;
    clear: both;
}

* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: inherit;
    color: inherit;
    font-family: 'Roboto', sans-serif;
}

img {
    display: block;
    max-width: 100%;
}

.wrap {
    position: relative;
    width: 1220px;
    padding: 0 10px;
    max-width: 100%;
    margin: 0 auto;
}

#burger,
#mobnav {
    display: none;
}

body {
    background-size: var(--bg-size);
    font-size: 62.5%;
    background-color: #333;
    background-image: url('/site/templates/img/banner1.jpg');
    background-attachment: fixed;
    /* background-size:55%; */
    background-position: center;
    padding: 7.5%;
}

header {
    float: left;
    width: 25%;
    padding-right: 2em;
}

nav {
    float: right;
    margin-bottom: 3em;
}

nav ul {
    list-style: none;
    float: left;
}

nav li {
    float: right;
    clear: right;
    margin-bottom: 0.25em;
}

nav a {
    font-family: 'Chakra Petch', sans-serif;
    float: left;
    color: var(--colour3);
    text-decoration: none;
    font-size: 1.8em;
    padding: 0.25em;
    line-height: 1;
}

nav a:hover {
    animation-name: rainbow;
    animation-duration: 2s;
    animation-iteration-count: infinite;
}

#logo {
    float: left;
}

#logo img {
    width: 100%;
}

.content {
    float: right;
    width: 75%;
    padding-left: 2em;
}

.styled {
    color: var(--colour3);
    background-color: var(--colour1);
    box-shadow: var(--depth) var(--depth) 0 var(--colour2);
}

.styledinverse {
    color: var(--colour1);
    background-color: var(--colour2);
    box-shadow: var(--depth) var(--depth) 0 var(--colour3);
}

.app {
    width: 100%;
    height: 100%;
}

.connect {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
                        supported by Chrome, Edge, Opera and Firefox */

    display: block;
    /* text-align:right; */
    /* clear:right; */
    /* float:right; */
    font-size: 2em;
    padding: 0.25em 1em;
    text-decoration: none;
    font-family: 'Chakra Petch', sans-serif;
    font-weight: 300;
    float: left;
    clear: left;
    margin-bottom: 2.5em;
}

.connect:hover {
    animation-name: rainbow;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    cursor: pointer;
}

.box {
    float: left;
    clear: left;
    padding: 2.5em;
    margin-bottom: 2.5em;
}

.mintHeadingExtras {
    font-size: 4em;
    padding: 0;
    margin-bottom: 10px;
}

.mintPrice {
    font-size: 0.9em;
    font-family: 'Chakra Petch', sans-serif;
}

.flex {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
}

.fcw {
    float: left;
    clear: left;
    width: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin-bottom: 1em;
    font-weight: normal;
    font-family: 'Chakra Petch', sans-serif;
}

.main p,
.main li {
    font-size: 1.4em;
    line-height: 1.6;
    margin-bottom: 1em;
}

.main ul,
.main ol {
    margin: 2em;
}

.main li {
    padding-left: 1em;
}

h1 {
    float: left;
    display: inline;
    font-size: 5vw;
    margin-bottom: 0.5em;
    padding: 0 0.1em;
}

h2 {
    font-size: 2em;
    margin-bottom: 0;
}

label {
    margin-top: 0;
    margin-bottom: 1em;
    margin-right: 0.5em;
    font-weight: normal;
    font-family: 'Chakra Petch', sans-serif;
    display: inline-block;
    font-size: 1.5em;
}


/* color:var(--colour1);

background-color:var(--colour2);

box-shadow:var(--depth) var(--depth) 0 var(--colour3); */

input {
    font-size: 2em;
    color: var(--colour1);
    background-color: var(--colour2);
    border: 0;
    outline: 0;
    padding:0.5em;
    max-width:100%;
}

input:focus {
    outline: none!important;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    opacity: 1;
}

.align_left {
    float: left;
}

.align_right {
    float: right;
}

.align_center {
    text-align: center;
}

img.align_left {
    margin: 0.5em 1em 1em 0;
}

img.align_right {
    margin: 0.5em 0 1em 1em;
}

img.align_center {
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.left {
    float: left;
}

.right {
    float: right;
}

table {
    border-collapse: collapse;
}

th,
td {
    font-size: 1.4em;
    border: 1px solid var(--colour2);
    padding: 0.5em;
}

th {
    text-align-last: left;
}

footer {
    clear: both;
}

.copyright {
    color: #fff;
    margin-bottom: 3em;
}

.popup {
    position: fixed;
    background-color: #00000099;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    padding: 10%;
    display: none;
}

.popup .inner {
    position: absolute;
    left: 50%;
    top: 50%;
    width: 300px;
    text-align: center;
    transform: translate(-50%, -50%);
    padding-bottom: 3em;
}

.loading-hex {
    display: inline-block;
    width: 35px;
    margin-right: 1em;
    margin-bottom: -1.2em;
}

.popup .inner h2 {
    display: inline-block;
}

.popup .close {
    position: absolute;
    right: 5%;
    top: 5%;
    color: var(--colour3);
    font-size: 1.8em;
    text-decoration: none;
    background-color: var(--colour1);
    width: 30px;
    height: 30px;
    text-align: center;
    padding: 5px;
    line-height: 1;
}

.mobtext {
    display:none;
}

.mintbar {
    float:left;
    cleaR:left;
    width:100%;
    background-color:var(--colour3);
    padding:5px;
}

    .mintbarprog {
        float:left;
        height:25px;
        animation-name: rainbow;
        animation-duration: 2s;
        animation-iteration-count: infinite;
    }

.mintbox {
    display:grid;
    grid-template-columns: 1fr 300px;
    grid-gap:2em;
    max-width:100%;
}

    form {
        max-width:100%;
    }

    #placeholder{
        max-width:100%;
        width:300px;
    }

@keyframes rainbow {
    0% {
        background-color: #5e42cf;
    }
    10% {
        background-color: #032b44;
        color: #fff
    }
    20% {
        background-color: #eafd16;
        color: #000
    }
    30% {
        background-color: #340d42;
        color: #fff
    }
    40% {
        background-color: #ff5a28;
    }
    50% {
        background-color: #444;
        color: #fff
    }
    60% {
        background-color: #ddd;
        color: #000
    }
    70% {
        background-color: #7eedcc;
    }
    80% {
        background-color: #ff6801;
    }
    90% {
        background-color: #00ff97;
    }
    100% {
        background-color: #5e42cf;
    }
}

@media screen and (max-width:1049px) {
    .mintbox {
        grid-template-columns: 1fr;
    }

        #placeholder {
            width:100%;
        }
}

@media screen and (max-width:659px) {
    header {
        width: 35%;
    }
    .content {
        width: 65%;
    }
    nav a,
    .connect {
        font-size: 1.6em;
    }
    h1 {
        font-size: 8vw;
    }

    .mintHeadingExtras {
        font-size: 3em;
        padding: 0;
        margin-bottom: 10px;
    }

    label {
        font-size: 1.25em;
    }

    .mintbox .left,
    .mintbox .right {
        max-width:100%;
    }

    .mobtext {
        display:block;
    }
}

@media screen and (max-width:529px) {
    body {
        padding: 40vh 10vw;
    }
    header,
    .content {
        width: 100%;
        float: left;
        padding: 0;
    }
    .connect {
        float: left;
        clear: left;
        margin-bottom: 1em;
    }
    #mainnav {
        display: none;
    }
    .burger {
        float: left;
        padding: 1em;
        margin-bottom: 1em;
    }
    #burger {
        display: block;
        width: 30px;
    }
    nav,
    #mobnav,
    #mobnav li,
    #mobnav li a {
        float: left;
        clear: left;
    }
    nav {
        width: 100%;
        margin: 0 0 2em 0;
    }
    #mobnav {
        width: 100%;
    }
    #mobnav li {
        margin-bottom: 0.35em;
    }
    #mobnav a {
        font-size: 2em;
    }
    h1 {
        font-size: 10vw;
    }
    .copyright {
        margin-bottom: 40vh;
    }
    input,.box {
        width:100%;
    }
}

@media screen and (max-width:320px) {
    .mintHeadingExtras {
        font-size: 2em;
        padding: 0;
        margin-bottom: 10px;
    }

    label {
        font-size: 1em;
    }
}

.intro {}

.intro p {
    font-size: 1.4em;
    line-height: 1.6;
    margin-bottom: 1em;
}

.intro p:last-of-type {
    margin-bottom: 0;
}

.minted {
    font-family: 'Chakra Petch', sans-serif;
}

    .minted h2 {
        margin-bottom:0.25em;
    }